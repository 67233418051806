const palavras = [
    "IS CAFÉ, onde o Sabor Ganha Vida!",
    "Mais que Café, uma Jornada Sensorial: IS CAFÉ, Seu Destino para o Café Perfeito!",
    "IS CAFÉ- Onde a Qualidade Encontra a Tradição do Café.",
    "IS CAFÉ: Café com propósito.",
    "IS CAFÉ: Descubra o Mundo do Café, uma Xícara de Cada Vez.",
    "IS CAFÉ: Uma Viagem de Sabores e Aromas em Cada Gole.",
    "IS CAFÉ: Sinta o Sabor da Tradição em Cada Cápsula.",
    "IS CAFÉ: Elevando sua Experiência Cafeinada a Novos Patamares.",
    "IS CAFÉ: A Escolha Natural dos Amantes de Café."
]

export default function sortearPalavra() {
    // Gerar um índice aleatório dentro do intervalo do array de palavras
    var indice = Math.floor(Math.random() * palavras.length);
    // Retornar a palavra correspondente ao índice gerado
    return palavras[indice];
}