import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import "./style.css"

import Header from "../../Header";
import Carrosel from "../../Carrosel"
import Footer from "../../Footer";
import BotaoWhats from "../../ButtonWhats";

export default function PaginaHome() {
    return (
        <main id='main'>
            <Header />
            <Carrosel />

            <section className="beneficios">
                <h2>BENEFICIOS DO PLANO</h2>

                <div className="container-box">
                    <div className="box">
                        <div className="limite-img">
                            <img src="assets/cardapio.png" alt="" />
                        </div>
                        <p><span className="titulo_beneficio">Variedade Premium</span> Descubra uma seleção exclusiva de cafés especiais, cuidadosamente escolhidos para proporcionar uma experiência sensorial única. </p>
                    </div>

                    <div className="box">
                        <div className="limite-img">
                            <img src="assets/delivey.webp" alt="" />
                        </div>
                        <p><span className="titulo_beneficio">Conveniência na Sua Porta</span> Desfrute da comodidade de receber cafés frescos e de alta qualidade diretamente em sua casa, sem precisar sair.</p>
                    </div>

                    <div className="box box-inverso">
                        <div className="limite-img">
                            <img src="assets/plano.png" alt="" />
                        </div>
                        <p><span className="titulo_beneficio">Flexibilidade de Assinatura</span> Personalize seu plano de assinatura de acordo com suas preferências e necessidades, com opções de entrega mensal ou personalizada.</p>
                    </div>

                    <div className="box box-inverso">
                        <div className="limite-img">
                            <img src="assets/cafe9.jpg" alt="" />
                        </div>
                        <p><span className="titulo_beneficio">Experiência Exclusiva</span> Participe de um clube de café premium, onde cada xícara oferece um mergulho no mundo dos sabores complexos e aromas envolventes.</p>
                    </div>
                </div>
            </section>

            <section className="beneficios container-cafe">
                <h2>BENEFICIOS DO CAFE</h2>

                <div>
                    <Accordion className="acordion">
                        <Accordion.Item eventKey="0" className="caixa-acordion">
                            <Accordion.Header className="espaco-acordion">Tipo</Accordion.Header>
                            <Accordion.Body>
                                <h3>Tipo Exclusivo</h3>
                                Nosso compromisso com a excelência começa com a escolha cuidadosa dos grãos. Apenas trabalhamos com o tipo arábica, cultivado nas alturas das montanhas, acima de 900m, onde os microclimas proporcionam condições ideais para o desenvolvimento do café, resultando em grãos da variedade Catuaí Amarelo ou Vermelho, reconhecidos por sua qualidade excepcional.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="caixa-acordion">
                            <Accordion.Header>Pontuação</Accordion.Header>
                            <Accordion.Body>
                                <h3>Pontuação Premium</h3>
                                Na Iscafe, qualidade é nossa prioridade. Todos os nossos cafés são classificados como especiais, com uma pontuação acima de 85 pontos pela Specialty Coffee Association. Além disso, oferecemos uma linha gourmet, com notas sensoriais irresistíveis, pontuadas acima de 80 pontos, para os paladares mais exigentes.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className="caixa-acordion">
                            <Accordion.Header>Caracteristicas do Sabor</Accordion.Header>
                            <Accordion.Body>
                                <h3>Torra Média e Notas Frutadas</h3>
                                Nossa equipe de mestres torrefadores realiza uma torra cuidadosa e precisa, destacando as características únicas de cada grão. Optamos por uma torra média, que preserva o equilíbrio entre a doçura e a acidez do café, resultando em uma xícara de sabor suave e equilibrado, com delicadas notas frutadas que elevam a experiência de degustação.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className="caixa-acordion">
                            <Accordion.Header>Como Preparar</Accordion.Header>
                            <Accordion.Body>
                                <h3>Preparo Perfeito</h3>
                                Desfrute do verdadeiro sabor do café seguindo nossas recomendações de preparo. Recomendamos uma proporção de 7g (uma colher de sopa rasa) de café moído para cada 100ml de água, preparado no método de coador. Utilize água a uma temperatura de 90°C, logo antes de iniciar a fervura, para extrair os aromas e sabores do café de forma ideal.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>

            <BotaoWhats className='btnWhats'/>
            <Footer />
        </main>
    )
}