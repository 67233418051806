import React from "react";
import { Link } from 'react-router-dom'

import { useEffect, useState } from "react";
import { Container, ContentMobile } from "./MenuHamburguerStyles.ts";
import { TiThMenu } from "react-icons/ti";
import { IoCloseSharp } from "react-icons/io5";

export default function Header() {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const handleResize = () => {
        if (window.innerWidth > 428 && !menuOpen) {
            setMenuOpen(true)
        }
        if (window.innerWidth < 427 && menuOpen) {
            setMenuOpen(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [menuOpen])

    return (
        <Container>
            <ContentMobile onClick={toggleMenu}>
                {menuOpen ? <IoCloseSharp color="white" /> : <TiThMenu color="white" />}
            </ContentMobile>
            <Link to='/'>
                <img className="logo" src="assets/Ativo 3.png" alt="" />
            </Link>
                {menuOpen || window.innerWidth > 768 ? <nav>
                    {/*<Link className="links-navbar" to='/'>O CLUBE</Link>*/}
                    {/*<Link className="links-navbar" to='/'>ASSINE</Link>*/}
                    <Link className="links-navbar" to='/'>BLOG</Link>
                    {/*<Link className="links-navbar" to='/'>LOGIN</Link>*/}
                </nav> : <></>}
        </Container>
    )
}