import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PaginaHome from "../components/Pages/PaginaPrincipal";

const Rotas = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<PaginaHome />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Rotas