import styled from 'styled-components';

interface MenuProps {
    isOpen: boolean
}

export const Container = styled.nav<MenuProps>`
  background-color: #6F4E37;
  z-index:999;
  position: fixed;
  width:100%;
  top:-10px;
  height: 10vh;
  display: flex;
  align-items: center;
  padding: 50px 20px;
  justify-content: space-between;

  nav {
    display: flex;
    flex-direction: ${(props) => (props.isOpen ? "column" : "row")};
    gap: 2rem;
    padding-left: 0px;
    align-items: center;
    justify-content: center;
  }

 
  .links-navbar {
    color: #F5F5DC;
    font-weight: 700;
    text-decoration: none;
    align-items: center;
  }


  .logo {
    width:110px;
  }

  @media(max-width: 450px) {
    justify-content: space-between;
    margin-top: 10px;
    border-radius: 0;
    flex-direction: row-reverse;

    nav {
      flex-direction: column;
      position: absolute;
      top: 70px;
      right: 0px;
      padding-left: 0px;
      width: 50%;
      background-color: #6F4E37;
      z-index:9999;
      justify-content: center;
      align-items: center;
      padding: 30px 0;
    }

  }

`

export const ContentMobile = styled.div`
  display: none;

  @media(max-width: 450px) {
    display: flex;
    padding-right: 20px;
  }
`