import React from "react";
import { Link } from 'react-router-dom'
import './style.css'

import { FaInstagram } from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import sortearPalavra from "./ListaFrase";

export default function Footer() {

    const palavraSorteada = sortearPalavra();

    return (
        <footer id="footer">
            <div className="limita-footer">
                <div className="box-info">
                    <Link className="links-info" to='/'>FAQ</Link>
                    <Link className="links-info politica" to='/'>Politica de Compra</Link>
                    <Link className="links-info" to='/'>Sobre</Link>
                    <Link className="links-info" to='/'>Contato</Link>
                </div>

                <div className="box-frase">
                    <img src="assets/Ativo 3.png" alt="" />
                    <p className="solto-mobile">{palavraSorteada}</p>
                </div>

                <div className="box-redesocial">
                    <Link className="link-redesocial" target="blank" to='https://www.instagram.com/iscafe_es/'><FaInstagram className="img"/></Link>
                    <Link className="link-redesocial" target="blank" to='https://www.youtube.com/@Iscafe-ClubedoCafezista'><CiYoutube className="img" /></Link>
                    <Link className="link-redesocial" target="blank"  to='https://api.whatsapp.com/send?phone=5527981688888'><FaWhatsapp className="img" /></Link>
                </div>
            </div>
        </footer>
    )
}